.process-flow {
  $self: &;

  @apply flex justify-center relative;

  &::after {
    @apply absolute h-px opacity-50 bg-secondary;

    top: 0.75rem;
    left: 2rem;
    right: 2rem;
    content: '';
  }

  &__item {
   @apply flex relative flex-col items-center relative z-10;

    width: 4rem;

    &_current {
      #{$self}__item-number {
        @apply text-white bg-secondary;
      }

      #{$self}__item-text {
        @apply text-secondary;
      }
    }

    &_completed {
      #{$self}__item-number {
        @apply text-secondary border-secondary;

        font-size: 0.625rem;
      }
    }
  }

  &__item-number {
    @apply flex justify-center items-center text-xs leading-6 rounded-full border text-secondary border-secondary bg-white;

    width: 1.5rem;
    height: 1.5rem;
  }

  &__item-text {
    @apply font-normal tracking-wide leading-none text-center uppercase text-secondary;

    font-size: 0.625rem;
    margin-top: 0.625rem;
  }
}
