@keyframes slide-in-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.animate-slide-in-up {
  animation: slide-in-up 0.3s ease-in-out;
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

.animate-slide-in-left {
  animation: slide-in-left 0.3s ease-in-out;
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.animate-slide-in-right {
  animation: slide-in-right 0.3s ease-in-out;
}

@keyframes grow-vertical {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

.animate-grow-vertical {
  animation: grow-vertical 0.3s ease-in-out;
}

@keyframes grow-horizontal {
  0% {
    transform: scaleX(0);
  }

  80% {
    transform: scaleX(1.1);
  }

  100% {
    transform: scaleX(1);
  }
}

.animate-grow-horizontal {
  animation: grow-horizontal 0.3s ease-in-out;
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-zoom-in {
  animation: zoom-in 0.3s ease-in-out;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animate-fade-out {
  animation: fade-out 0.3s ease-in-out;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
