/* stylelint-disable selector-id-pattern */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
    -webkit-text-fill-color: #333;
  }

  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px white inset; /* your box-shadow */
    -webkit-text-fill-color: #333;
  }
}

@import '_typography';
@import '_animation';
@import 'components';
