@layer components {
  .progress {
    @apply fixed top-0 left-0 z-50 w-full bg-primary;

    height: 0.125rem;

    &__peg {
      @apply block absolute top-0 right-0 h-full opacity-100;

      width: 6.25rem;
      box-shadow: 0 0 10px #64a08b, 0 0 5px #64a08b;
      transform: rotate(3deg) translate(0, -0.25rem);
    }
  }
}
