@layer components {
  $toggle-switch-radius: 0.75rem;

  .toggle-switch {
    border-radius: $toggle-switch-radius;
    width: $toggle-switch-radius * 4;

    @apply border-none overflow-hidden;

    &__checkbox {
      @apply hidden;
    }

    &__background {
      padding: 1px;
      transition: background-color 0.25s ease-in 0s;

      @apply bg-gray-2 block cursor-pointer;
    }

    &__switch {
      border-radius: $toggle-switch-radius;
      height: $toggle-switch-radius * 2;
      transition: margin-left 0.25s ease-in 0s;
      width: $toggle-switch-radius * 2;

      @apply bg-white border-none;
    }

    &__checkbox:checked + &__background {
      @apply bg-primary;
    }

    &__checkbox:checked + &__background &__switch {
      margin-left: calc(50% - 1px);
    }

    &_disabled &__background {
      @apply cursor-not-allowed opacity-50;
    }
  }
}
