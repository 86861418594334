@layer components {
  .form-label {
    @apply inline-block p-0 mb-2 text-xs;
  }

  .form-field {
    @apply block py-2 px-4 w-full text-base font-normal placeholder-gray-1 bg-white border truncate border-gray-2 rounded-sm;

    min-height: 3.4375rem;
  }

  .form-error {
    @apply mt-1 text-xs text-danger text-left;
  }
}
